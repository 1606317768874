<template>
  <div>
    <TaskManager class="mt-3 mb-3" :id="$route.params.id" system="circuit_arrivee"></TaskManager>

    <div class="w-100 mb-3 p-3 rounded shadow-sm d-flex flex-column" v-background-3>
      <base-input-area
        v-model="task_commentaire"
        text="Commentaire"
        :errors="feedback.task_commentaire"
      ></base-input-area>
      <base-button
        class="ml-auto"
        text="Enregistrer"
        icon="save"
        @click="updateCollaborateur"
        :loading="loading"
      ></base-button>
    </div>
  </div>
</template>
<script>
import TaskManager from "@/components/application/TasksManager.vue";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: {
    TaskManager,
    BaseInputArea,
    BaseButton,
  },
  data() {
    return {
      loading: false,
      feedback: {},
    };
  },
  methods: {
    ...mapActions({
      _updateCollaborateur: "collaborateurs/updateCollaborateur",
    }),
    updateCollaborateur: function () {
      this.feedback = {};
      this.loading = true;
      this._updateCollaborateur()
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    ...mapFields("collaborateurs", ["collaborateur.task_commentaire"]),
  },
};
</script>
<style lang="css"></style>
