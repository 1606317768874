<template>
  <div v-background-3 class="rounded shadow-sm">
    <div class="d-flex justify-content-between align-items-center p-2 pl-3 pr-3" :class="qwarkBorder">
      <h6 class="m-0">Tâches à réaliser</h6>
      <div class="d-flex align-items-center">
        <button-circle v-if="showdeleteBtn" name="unlock" size="18" @click="showdeleteBtn = false"></button-circle>
        <button-circle v-if="!showdeleteBtn" name="lock" size="18" @click="showdeleteBtn = true"></button-circle>
        <v-popover>
          <div class="text-primary cursor-pointer ml-3">Ajouter</div>
          <template slot="popover">
            <div class="text-left" style="min-width: 200px">
              <div class="mb-4">Saisir le libellé de la tâche</div>
              <div>
                <base-input
                  v-model="newTask.description"
                  inputText="Saisir"
                  :errors="feedback.description"
                  darkBackgroundMode
                ></base-input>
                <button class="btn btn-primary btn-block mt-3" @click="storeTaskLibelles(newTask)">Ajouter</button>
              </div>
            </div>
          </template>
        </v-popover>
      </div>
    </div>
    <div v-if="!loading" class="p-3">
      <div v-for="lib in _tasks" :key="lib.id" class="d-flex align-items-center">
        <button-circle
          v-if="!lib.checked && !showdeleteBtn"
          @click="storeTask(lib.id)"
          :loading="loadingCheck"
          name="circle"
          size="18"
        ></button-circle>
        <button-circle
          v-if="lib.checked && !lib.na && !showdeleteBtn"
          @click="updateTaskNa(lib.id)"
          :loading="loadingCheck"
          name="check-circle"
          size="18"
        ></button-circle>
        <button-circle
          v-if="lib.checked && lib.na && !showdeleteBtn"
          @click="deleteTask(lib.id)"
          :loading="loadingCheck"
          name="minus-circle"
          size="18"
        ></button-circle>
        <button-circle
          v-if="showdeleteBtn"
          @click="deleteTaskLibelles(lib.id)"
          name="trash-2"
          size="18"
        ></button-circle>
        <span v-if="showdeleteBtn" class="ml-2">{{ lib.description }}</span>
        <span v-if="!lib.checked && !showdeleteBtn" @click.stop="storeTask(lib.id)" class="ml-2 cursor-pointer">{{
          lib.description
        }}</span>
        <span v-if="lib.checked && !showdeleteBtn" class="ml-2 cursor-pointer" @click.stop="deleteTask(lib.id)">{{
          lib.description
        }}</span>
      </div>
    </div>
    <div class="p-3" v-else>Chargement...</div>
  </div>
</template>
<script>
import BaseInput from "@/components/bases/Input.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  components: { BaseInput, ButtonCircle },
  data() {
    return {
      showdeleteBtn: false,
      feedback: {},
      loading: false,
      loadingCheck: false,
      tasks: [],
      taskLibelles: [],
      newTask: { description: "" },
    };
  },
  props: {
    system: {
      Type: String,
      Requierd: true,
    },
    id: {
      Type: Number,
      Requierd: true,
    },
  },
  methods: {
    /**
     * ********************************************************************
     */

    getTasks: function () {
      this.loading = true;
      this.$http
        .get("/tasks", {
          params: {
            system: this.system,
            field_key: this.id,
          },
        })
        .then((response) => (this.tasks = response.data))
        .catch(() => {})
        .finally(() => (this.loading = false));
    },

    storeTask: function (id) {
      this.loadingCheck = true;
      this.$http
        .put("/tasks", {
          libelle_id: id,
          field_key: this.id,
          system: this.system,
        })
        .then((response) => {
          this.tasks.push({
            id: response.data.id,
            libelle_id: response.data.libelle_id,
            field_key: response.data.field_key,
            system: response.data.system,
            na: false,
          });
        })
        .catch(() => {})
        .finally(() => (this.loadingCheck = false));
    },

    updateTaskNa: function (id) {
      this.loadingCheck = true;
      this.$http
        .post("/tasks", {
          libelle_id: id,
          field_key: this.id,
          system: this.system,
        })
        .then((response) => {
          const index = this.tasks.findIndex((x) => x.libelle_id === id);
          this.tasks.splice(index, 1);

          this.tasks.push({
            id: response.data.id,
            libelle_id: response.data.libelle_id,
            field_key: response.data.field_key,
            system: response.data.system,
            na: true,
          });
        })
        .catch(() => {})
        .finally(() => (this.loadingCheck = false));
    },

    /*updateTask: function(task) {
      this.loading = true;

      this.$http
        .post("/tasks", task)
        .then(() => {})
        .catch(() => {})
        .finally(() => (this.loading = false));
    },*/

    deleteTask: function (id) {
      this.loadingCheck = true;
      this.$http
        .delete("/tasks", {
          params: {
            libelle_id: id,
            field_key: this.id,
            system: this.system,
          },
        })
        .then(() => {
          const index = this.tasks.findIndex((x) => x.libelle_id === id);
          this.tasks.splice(index, 1);
        })
        .catch(() => {})
        .finally(() => (this.loadingCheck = false));
    },

    /**
     * ********************************************************************
     */

    getTasksLibelles: function () {
      this.loading = true;
      this.$http
        .get("/tasks/libelle", {
          params: {
            system: this.system,
          },
        })
        .then((response) => (this.taskLibelles = response.data))
        .catch(() => {})
        .finally(() => (this.loading = false));
    },

    storeTaskLibelles: function (taskLib) {
      this.loading = true;
      this.$http
        .put("/tasks/libelle", {
          description: taskLib.description,
          system: this.system,
        })
        .then(() => this.getTasksLibelles())
        .catch(() => {})
        .finally(() => {
          this.loading = false;
          this.newTask.description = "";
        });
    },

    updateTaskLibelles: function (taskLib) {
      this.loading = true;
      this.$http
        .post("/tasks/libelle", {
          id: taskLib.id,
          description: taskLib.description,
        })
        .then(() => {})
        .catch(() => {})
        .finally(() => (this.loading = false));
    },

    deleteTaskLibelles: function (id) {
      if (
        window.confirm(
          "Attention la tâche sera supprimée définitivement pour tous les enregistrements, veuillez confirmer"
        )
      ) {
        this.loading = true;
        this.$http
          .delete("/tasks/libelle/" + id)
          .then(() => {
            this.getTasksLibelles();
            this.showdeleteBtn = false;
          })
          .catch(() => {})
          .finally(() => (this.loading = false));
      }
    },

    /**
     * ********************************************************************
     */
  },
  mounted() {
    this.getTasks();
    this.getTasksLibelles();
  },
  computed: {
    _tasks() {
      let d = this.taskLibelles;
      d.forEach((taskLibelle) => {
        taskLibelle.checked = this.tasks.some((task) => task.libelle_id === taskLibelle.id);
        taskLibelle.na = this.tasks.some((task) => task.libelle_id === taskLibelle.id && task.na == true);
      });
      console.log("d", d);
      return d;
    },
    qwarkBorder: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    },
  },
};
</script>
<style lang="css"></style>
